import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { AuthContext } from '../../contexts/AuthContext';
import ScreenLoader from '../../components/ScreenLoader';
import Login from '../../routes/Public/Login';
import NotFound from '../../routes/Public/NotFound';
import Error from '../../routes/Public/Error';

export default function Public() {
  const { auth } = useContext(AuthContext);

  const [cookies] = useCookies(['auth']);
  const [unAuthed, setUnAuthed] = useState(null);

  useEffect(() => {
    if (auth === true) {
      setUnAuthed(false);
    } else {
      if (cookies.auth) {
        setUnAuthed(false);
      } else {
        setUnAuthed(true);
      }
    }
  }, [auth]);

  return unAuthed ? (
    <Routes>
      <Route
        path='/'
        element={
          <Suspense fallback={<ScreenLoader size='medium' />}>
            <Login />
          </Suspense>
        }
        errorElement={<Error />}
      />
      <Route
        path='*'
        element={
          <Suspense fallback={<ScreenLoader size='medium' />}>
            <NotFound type='external' />
          </Suspense>
        }
      />
    </Routes>
  ) : null;
}
