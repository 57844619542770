import Helpdesk from '../navigator/Helpdesk';
import StatusEndUser from '../navigator/StatusEndUser';

export const ROLES = [
  {
    type: 'admin',
    role: 'Helpdesk',
    component: Helpdesk,
  },
  {
    type: 'viewer',
    role: 'StatusEndUser',
    component: StatusEndUser,
  },
];
