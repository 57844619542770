import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import styles from './headeright.module.scss';
import { RiLockPasswordFill, RiLogoutBoxFill, RiMenuFill } from 'react-icons/ri';
import { MdAccountCircle } from 'react-icons/md';
import { AuthContext } from '../../../contexts/AuthContext';
import MediaQuery from 'react-responsive';
import { ThemeContext } from '../../../contexts/ThemeContext';

export default function HeadeRight() {
  const { LogoutUser } = useContext(AuthContext);
  const { isSidebarOpened, setIsSidebarOpened } = useContext(ThemeContext);

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  const toggleMobileMenu = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.HeaderRight}>
      <div className={styles.Account}>
        <motion.button className={styles.AccountBtn} ref={buttonRef} onClick={toggleMenu}>
          <MdAccountCircle className={styles.AccountIcon} />
          <MediaQuery minWidth={580}>
            <span className={styles.AccountText}>Λογαριασμός</span>
          </MediaQuery>
        </motion.button>
        {isOpen ? (
          <motion.div
            ref={dropdownRef}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 10 }}
            transition={{ duration: 0.3 }}
            className={styles.Dropdown}>
            <ul>
              <li className={styles.DropdownItem}>
                <NavLink to='/change-password'>
                  <div className={styles.DropdownItemIcon}>
                    <RiLockPasswordFill />
                  </div>
                  <div className={styles.DropdownItemText}>Αλλαγή Κωδικού</div>
                </NavLink>
              </li>
              <li className={styles.DropdownItem}>
                <NavLink to='/logout' onClick={() => LogoutUser()}>
                  <div className={styles.DropdownItemIcon}>
                    <RiLogoutBoxFill />
                  </div>
                  <div className={styles.DropdownItemText}>Αποσύνδεση</div>
                </NavLink>
              </li>
            </ul>
          </motion.div>
        ) : null}

        <MediaQuery maxWidth={991}>
          <button className={styles.MobileMenu} onClick={toggleMobileMenu}>
            <RiMenuFill />
          </button>
        </MediaQuery>
      </div>
    </div>
  );
}
