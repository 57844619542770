import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { useFormik } from 'formik';
import styles from './index.module.scss';
import * as yup from 'yup';

import { AuthContext } from '../../../contexts/AuthContext';
import InputText from '../../../components/InputText';
import InputPassword from '../../../components/InputPassword';
import PrimaryBtn from '../../../components/PrimaryBtn';
import Loader from '../../../components/Loader';

export default function Login() {
  const { LoginUser, loaderVisible, errorCallMsg } = useContext(AuthContext);

  // settings for framer-motion
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  // forms & validation
  const loginValidationSchema = yup.object().shape({
    username: yup
      .string()
      .email('Παρακαλώ εισάγετε έγκυρη διεύθυνση email')
      .required('Το όνομα χρήστη είναι υποχρεωτικό'),
    password: yup.string().required('Ο κωδικός είναι υποχρεωτικός'),
  });

  const formik = useFormik({
    validationSchema: loginValidationSchema,
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: (values) => {
      LoginUser(values.username, values.password);
    },
  });

  return (
    <motion.div className={styles.LoginPage} variants={container} initial='hidden' animate='show'>
      <div className={styles.LoginPageContainer}>
        <motion.div className={styles.LoginHeader} variants={item}>
          <div className='logo'>Status</div>
        </motion.div>
        <div className={styles.LoginMain}>
          <div className={styles.LoginMainContainer}>
            <motion.h1 className={styles.h1} variants={item}>
              Σύνδεση Χρήστη
            </motion.h1>
            <motion.h2 variants={item}>Καλωσήρθατε στο Status Reports</motion.h2>

            <motion.form
              className={styles.LoginForm}
              onSubmit={formik.handleSubmit}
              variants={item}>
              <InputText
                formik={formik}
                label='Όνομα χρήστη'
                placeholder=''
                type='text'
                name='username'
                value={formik.values.username}
                errors={formik.errors.username}
                touched={formik.touched.username}
              />
              <InputPassword
                formik={formik}
                label='Κωδικός πρόσβασης'
                placeholder='********'
                name='password'
                value={formik.values.password}
                errors={formik.errors.password}
                touched={formik.touched.password}
              />
              <PrimaryBtn type='submit' fullWidth title='ΣΥΝΔΕΣΗ' />

              {errorCallMsg ? (
                <div style={{ paddingTop: 20, fontSize: 15, color: '#ff0000' }}>{errorCallMsg}</div>
              ) : null}
            </motion.form>
          </div>

          <motion.div className={styles.LoginFooter} variants={item}>
            AN APP DESIGNED AND DEVELOPED WITH LOVE BY MAINSYS
          </motion.div>
        </div>

        {loaderVisible ? (
          <motion.div
            initial={{ opacity: 0, translateY: -10 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: -10 }}
            transition={{ duration: 0.25 }}
            style={{
              zIndex: 10,
              backgroundColor: 'rgba(255,255,255,0.7)',
              width: '100%',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
            }}>
            <Loader size='medium' />
          </motion.div>
        ) : null}
      </div>
    </motion.div>
  );
}
