import React, { createContext, useContext, useState } from 'react';

export const ThemeContext = createContext({});

export const ThemeContextProvider = ({ children }) => {
  const [isSidebarToggled, setIsSidebarToggled] = useState(false);
  const [isSidebarOpened, setIsSidebarOpened] = useState(true);

  const themeContextValue = {
    isSidebarToggled,
    setIsSidebarToggled,
    isSidebarOpened,
    setIsSidebarOpened,
  };

  return <ThemeContext.Provider value={themeContextValue}>{children}</ThemeContext.Provider>;
};

export const useStateContext = () => useContext(ThemeContext);
