import { useContext } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import styles from './sidebarbottom.module.scss';
import { RiAccountCircleFill } from 'react-icons/ri';
import { ThemeContext } from '../../../contexts/ThemeContext';

export const SidebarBottom = () => {
  const { user } = useContext(AuthContext);
  const { isSidebarToggled } = useContext(ThemeContext);

  return (
    <div className={[styles.AccountCard, isSidebarToggled ? styles._isToggled : null].join(' ')}>
      <div className={styles.CardLeft}>
        <div className={styles.CardIcon}>
          <RiAccountCircleFill />

          {user.isActive && <div className={styles.UserIsActive}></div>}
        </div>
      </div>
      <div className={styles.CardRight}>
        <div className={styles.AccountName}>{`${user.firstName} ${user.lastName}`}</div>
        <div className={styles.AccountInfo}>
          <div className={styles.AccountEmail}>{user.email}</div>
        </div>
      </div>
    </div>
  );
};

export default SidebarBottom;
