import loader from '../../assets/tail-spin.svg';
import React, { useState, useEffect } from 'react';

export default function ScreenLoader({ size = 'small' }) {
  const [loaderSize, setLoaderSize] = useState(32);
  useEffect(() => {
    switch (size) {
      case 'small':
        setLoaderSize(32);
        break;
      case 'medium':
        setLoaderSize(48);
        break;
      case 'large':
        setLoaderSize(72);
        break;
      default:
        setLoaderSize(32);
    }
  }, [loaderSize, size]);

  return (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <img src={loader} width={loaderSize} height={loaderSize} alt='screen loader' />
    </div>
  );
}
