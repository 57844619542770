import React from 'react';
import { motion } from 'framer-motion';
import styles from './index.module.scss';

export default function PrimaryBtn({
  type,
  title,
  icon = null,
  fullWidth = false,
  onClick,
  delay = 0,
  dangerBtn = false,
}) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.25, delay: delay }}>
      <button
        className={
          icon
            ? dangerBtn
              ? styles.BtnDangerIcon
              : styles.BtnIcon
            : dangerBtn
            ? styles.BtnDanger
            : styles.Btn
        }
        style={{ width: fullWidth ? '100%' : 'auto' }}
        type={type}
        onClick={onClick}>
        {icon ? icon : null}
        {title}
      </button>
    </motion.div>
  );
}
