import React, { useContext, lazy, Suspense } from 'react';
import Public from './navigator/Public';
import {
  // createBrowserRouter,
  createHashRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { useCookies } from 'react-cookie';
import { AuthContext } from './contexts/AuthContext';
import privateAxios from './network/private-axios';
import Helpdesk from './navigator/Helpdesk';
import { ROLES } from './data/systemRoles';
import ScreenLoader from './components/ScreenLoader';
import StatusEndUser from './navigator/StatusEndUser';

function App() {
  const [cookies] = useCookies(['auth']);
  const { auth, user } = useContext(AuthContext);

  if (cookies.auth) {
    privateAxios.defaults.headers.common['Authorization'] = `Bearer ${cookies.auth.token}`;
  }

  const CheckRole = (role) => {
    if (role === 'Helpdesk') {
      return <Helpdesk />;
    }
    if (role === 'StatusEndUser') {
      return <StatusEndUser />;
    }
  };

  const CheckRole1 = (role) => {
    ROLES.map((r, index) => {
      if (r.role === role) {
        const Component = React.createElement(
          eval(r.component) || (() => <div>Unknown Component</div>)
        );

        return <Component key={index} />;
      }
    });
  };

  const Router = createHashRouter(
    createRoutesFromElements(<Route path='*' element={auth ? CheckRole(user?.role) : <Public />} />)
  );

  return <RouterProvider router={Router} />;
}

export default App;
