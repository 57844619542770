import React, { useState } from 'react';
import { motion } from 'framer-motion';
import styles from './index.module.scss';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';
import InputError from '../InputError';

export default function InputPassword({
  label,
  placeholder,
  formik,
  name,
  value,
  errors,
  touched,
  hasErrorOutside = null,
  delay = 0,
  autocomplete = null,
  id = null,
}) {
  const [passVisible, setPassVisible] = useState(false);

  return (
    <motion.div
      className='InputContainer'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.25, delay: delay }}>
      <label className={styles.InputTextLabel}>{label}</label>
      <div
        className={
          (errors && touched) || hasErrorOutside
            ? `${styles.InputTextContainer} ${styles.InputTextContainerError}`
            : styles.InputTextContainer
        }>
        <input
          id={id}
          className={styles.InputText}
          type={passVisible ? 'text' : 'password'}
          name={name}
          placeholder={placeholder}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={value}
          autoComplete={autocomplete}
        />
        <div className={styles.Eye} onClick={() => setPassVisible(!passVisible)}>
          {passVisible ? <IoEyeOutline size={18} /> : <IoEyeOffOutline size={18} />}
        </div>
      </div>
      {errors && touched && <InputError error={errors} />}
    </motion.div>
  );
}
