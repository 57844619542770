import loader from '../../assets/tail-spin.svg';
import React, { useState, useEffect } from 'react';
import styles from './loader.module.scss';

export default function Loader({ size = 'small' }) {
  const [loaderSize, setLoaderSize] = useState(32);
  useEffect(() => {
    switch (size) {
      case 'extra-small':
        setLoaderSize(26);
        break;
      case 'small':
        setLoaderSize(32);
        break;
      case 'medium':
        setLoaderSize(48);
        break;
      case 'large':
        setLoaderSize(72);
        break;
      default:
        setLoaderSize(32);
    }
  }, [loaderSize, size]);

  return (
    <div className={styles.LoaderContainer}>
      <img src={loader} width={loaderSize} height={loaderSize} alt='loader' />
    </div>
  );
}
