import React from 'react';
import { useRouteError } from 'react-router-dom';

export default function Error() {
  let error = useRouteError();
  console.error(error);

  // Uncaught ReferenceError: path is not defined
  return (
    <section>
      <h1>Error!</h1>
      <small>{error?.message}</small>
    </section>
  );
}
