import { useMediaQuery } from 'react-responsive';

function CustomMediaQueries({ children }) {
  const isPhoneDown = useMediaQuery({ query: '(max-width: 480px)' });
  const isPhoneUp = useMediaQuery({ query: '(min-width: 481px)' });
  const isTabletDown = useMediaQuery({ query: '(max-width: 767px)' });
  const isTabletUp = useMediaQuery({ query: '(min-width: 768px)' });
  const isDesktopSmallDown = useMediaQuery({ query: '(max-width: 991px)' });
  const isDesktopSmallUp = useMediaQuery({ query: '(min-width: 992px)' });
  const isDesktopDown = useMediaQuery({ query: '(max-width: 1280px)' });
  const isDesktopUp = useMediaQuery({ query: '(min-width: 992px)' });

  return children({
    isPhoneDown,
    isPhoneUp,
    isTabletUp,
    isTabletDown,
    isDesktopSmallDown,
    isDesktopSmallUp,
    isDesktopDown,
    isDesktopUp,
  });
}

export default CustomMediaQueries;
