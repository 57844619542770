import React from 'react';
import { motion } from 'framer-motion';
import styles from './index.module.scss';
import InputError from '../InputError';

export default function InputText({
  label,
  type,
  placeholder,
  formik,
  name,
  value,
  errors,
  touched,
  hasErrorOutside = null,
  disabled = false,
  delay = 0,
  autocomplete = null,
  id = null,
}) {
  return (
    <motion.div
      className='InputContainer'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.25, delay: delay }}>
      <label className={styles.InputTextLabel}>{label}</label>
      <div
        className={
          (errors && touched) || hasErrorOutside
            ? `${styles.InputTextContainer} ${styles.InputTextContainerError}`
            : styles.InputTextContainer
        }
        style={disabled ? { opacity: 0.6 } : null}>
        {disabled ? (
          <div className={styles.InputText}>{value}</div>
        ) : (
          <input
            id={id}
            className={styles.InputText}
            type={type}
            name={name}
            placeholder={placeholder}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={value}
            autoComplete={autocomplete}
          />
        )}
      </div>
      {errors && touched && <InputError error={errors} />}
    </motion.div>
  );
}
