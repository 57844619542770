import { VscAccount, VscBracketError } from 'react-icons/vsc';
import { RiNumbersLine } from 'react-icons/ri';
import { TbChartInfographic, TbFileInfo } from 'react-icons/tb';
import { IoCalendarNumberSharp, IoAlbumsOutline } from 'react-icons/io5';
import { MdDashboard, MdHome } from 'react-icons/md';
import { HiUsers, HiUser, HiUserPlus } from 'react-icons/hi2';
import { HiPresentationChartBar } from 'react-icons/hi';
import { ImStatsDots } from 'react-icons/im';
import { PiBracketsCurlyBold } from 'react-icons/pi';
import {
  BsDatabaseFill,
  BsDatabaseFillAdd,
  BsCalendarDay,
  BsFillBarChartLineFill,
} from 'react-icons/bs';

export const HelpdeskLinks = [
  {
    title: 'Users',
    url: '',
    links: [
      {
        name: 'Users List',
        url: '/users',
        icon: <VscAccount />,
      },
      {
        name: 'Add User',
        url: '/add-user',
        icon: <VscAccount />,
      },
    ],
  },
  {
    title: 'Status Users Databases',
    url: '/status-users-databases',
    links: [],
  },
];

export const StatusUsersLinks = [
  {
    title: 'Status Users',
    links: [
      {
        name: 'Χρήστες',
        icon: <VscAccount />,
      },
    ],
  },
  {
    title: 'Σύνδεσμοι',
    links: [
      {
        name: 'Ανάλυση Ημέρας',
        icon: <IoCalendarNumberSharp />,
      },
      {
        name: 'Στατιστικά Πωλήσεων',
        icon: <RiNumbersLine />,
      },
      {
        name: 'Στατιστικά Αγορών',
        icon: <TbChartInfographic />,
      },
      {
        name: 'Απογραφή',
        icon: <IoAlbumsOutline />,
      },
    ],
  },
];

export const AppMenuLinks = [
  {
    Helpdesk: [
      // {
      //   title: 'Mainsys',
      //   url: '',
      //   active: true,
      //   iconLabel: <MdHome />,
      //   submenu: [
      //     {
      //       name: 'Dashboard',
      //       url: '/',
      //       icon: <MdDashboard />,
      //     },
      //   ],
      // },
      {
        title: 'ΠΙΝΑΚΑΣ ΕΛΕΓΧΟΥ',
        url: '/',
        active: true,
        singleItem: true,
        iconLabel: <MdDashboard />,
        submenu: [],
      },
      {
        title: 'Users',
        url: '',
        active: true,
        iconLabel: <HiUser />,
        submenu: [
          {
            name: 'Users List',
            url: '/users',
            icon: <HiUsers />,
          },
          {
            name: 'Add User',
            url: '/add-user',
            icon: <HiUserPlus />,
          },
        ],
      },
      {
        title: 'Status Users Databases',
        url: '',
        active: true,
        iconLabel: <BsDatabaseFill />,
        submenu: [
          {
            name: 'Databases List',
            url: '/databases',
            icon: <BsDatabaseFill />,
          },
          {
            name: 'Add Database',
            url: '/add-database',
            icon: <BsDatabaseFillAdd />,
          },
        ],
      },
      {
        title: 'Logs',
        url: '',
        active: true,
        iconLabel: <PiBracketsCurlyBold />,
        submenu: [
          {
            name: 'Exceptions',
            url: '/exceptions',
            icon: <VscBracketError />,
          },
          {
            name: 'Workers Logs',
            url: '/workers-logs',
            icon: <TbFileInfo />,
          },
        ],
      },
    ],
    StatusEndUser: [
      {
        title: 'ΠΙΝΑΚΑΣ ΕΛΕΓΧΟΥ',
        url: '/',
        active: true,
        singleItem: true,
        iconLabel: <MdDashboard />,
        submenu: [],
      },
      {
        title: 'ΑΝΑΛΥΣΗ',
        url: '',
        active: true,
        iconLabel: <BsCalendarDay />,
        submenu: [
          {
            name: 'Ανάλυση Ημέρας',
            url: '/analusi-imeras',
            icon: '',
          },
        ],
      },
      {
        title: 'ΣΤΑΤΙΣΤΙΚΑ ΠΩΛΗΣΕΩΝ',
        url: '',
        active: true,
        iconLabel: <HiPresentationChartBar />,
        submenu: [
          {
            name: 'Ανά Κατηγορία',
            url: '/statistika-pwlisewn/category',
            icon: '',
          },
          {
            name: 'Ανά Κατασκευαστή',
            url: '/statistika-pwlisewn/manufacturer',
            icon: '',
          },
          {
            name: 'Ανά Τύπο Κατασκευαστή',
            url: '/statistika-pwlisewn/manufacturer-type',
            icon: '',
          },
        ],
      },
      {
        title: 'ΣΤΑΤΙΣΤΙΚΑ ΑΓΟΡΩΝ',
        url: '',
        active: true,
        iconLabel: <ImStatsDots />,
        submenu: [
          {
            name: 'Ανά Κατηγορία',
            url: '/statistika-agorwn/category',
            icon: '',
          },
          {
            name: 'Ανά Κατασκευαστή',
            url: '/statistika-agorwn/manufacturer',
            icon: '',
          },
          {
            name: 'Ανά Τύπο Κατασκευαστή',
            url: '/statistika-agorwn/manufacturer-type',
            icon: '',
          },
        ],
      },
      {
        title: 'ΑΠΟΓΡΑΦΗ',
        url: '',
        active: true,
        iconLabel: <BsFillBarChartLineFill />,
        submenu: [
          {
            name: 'Ανά Κατηγορία',
            url: '/apografi/category',
            icon: '',
          },
          {
            name: 'Ανά Κατασκευαστή',
            url: '/apografi/manufacturer',
            icon: '',
          },
          {
            name: 'Ανά Τύπο Κατασκευαστή',
            url: '/apografi/manufacturer-type',
            icon: '',
          },
        ],
      },
    ],
    StatusEndUser2: [
      {
        title: 'Menu',
        url: '',
        active: false,
        submenu: [
          {
            name: 'Ανάλυση Ημέρας',
            url: '/analusi-imeras',
            icon: <BsCalendarDay />,
            active: true,
            submenu: [
              {
                name: 'Databases List',
                url: '/databases',
                icon: <BsDatabaseFill />,
              },
              {
                name: 'Add Database',
                url: '/add-database',
                icon: <BsDatabaseFillAdd />,
              },
            ],
          },
          {
            name: 'Στατιστικά Πωλήσεων',
            url: '/statistika-pwlisewn',
            icon: <HiPresentationChartBar />,
          },
          {
            name: 'Στατιστικά Αγορών',
            url: '/statistika-agorwn',
            icon: <ImStatsDots />,
          },
          {
            name: 'Απογραφή',
            url: '/apografi',
            icon: <BsFillBarChartLineFill />,
          },
        ],
      },
    ],
  },
];
