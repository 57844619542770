import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../../layouts/Layout/Layout';
import ScreenLoader from '../../components/ScreenLoader';
import Error from '../../routes/Public/Error';
import NotFound from '../../routes/Public/NotFound';

const Dashboard = lazy(() => import('../../routes/StatusEndUser/Dashboard/Dashboard'));

const AnalusiHmeras = lazy(() => import('../../routes/StatusEndUser/AnalusiHmeras/AnalusiHmeras'));

const StatistikaPwlisewnCategory = lazy(() =>
  import('../../routes/StatusEndUser/StatistikaPwlisewn/Category/category')
);
const StatistikaPwlisewnManufacturer = lazy(() =>
  import('../../routes/StatusEndUser/StatistikaPwlisewn/Manufacturer/manufacturer')
);
const StatistikaPwlisewnManufacturerType = lazy(() =>
  import('../../routes/StatusEndUser/StatistikaPwlisewn/ManufacturerType/manufacturerType')
);

const StatistikaAgorwnCategory = lazy(() =>
  import('../../routes/StatusEndUser/StatistikaAgorwn/Category/category')
);
const StatistikaAgorwnManufacturer = lazy(() =>
  import('../../routes/StatusEndUser/StatistikaAgorwn/Manufacturer/manufacturer')
);
const StatistikaAgorwnManufacturerType = lazy(() =>
  import('../../routes/StatusEndUser/StatistikaAgorwn/ManufacturerType/manufacturerType')
);

const ApografiCategory = lazy(() =>
  import('../../routes/StatusEndUser/Apografi/Category/category')
);
const ApografiManufacturer = lazy(() =>
  import('../../routes/StatusEndUser/Apografi/Manufacturer/manufacturer')
);
const ApografiManufacturerType = lazy(() =>
  import('../../routes/StatusEndUser/Apografi/ManufacturerType/manufacturerType')
);

const ChangePassword = lazy(() =>
  import('../../routes/StatusEndUser/ChangePassword/ChangePassword')
);

const ComingSoon = lazy(() => import('../../routes/Public/ComingSoon/comingsoon'));

const SuspenseLayout = () => (
  <Suspense fallback={<ScreenLoader size='medium' />}>
    <Routes>
      <Route errorElement={<Error />}>
        <Route index path='/' element={<Dashboard />} />
        <Route exact path='/analusi-imeras' element={<AnalusiHmeras />} />
        <Route
          exact
          path='/statistika-pwlisewn/category'
          element={<StatistikaPwlisewnCategory />}
        />
        <Route
          exact
          path='/statistika-pwlisewn/manufacturer'
          element={<StatistikaPwlisewnManufacturer />}
        />
        <Route
          exact
          path='/statistika-pwlisewn/manufacturer-type'
          element={<StatistikaPwlisewnManufacturerType />}
        />
        <Route exact path='/statistika-agorwn/category' element={<StatistikaAgorwnCategory />} />
        <Route
          exact
          path='/statistika-agorwn/manufacturer'
          element={<StatistikaAgorwnManufacturer />}
        />
        <Route
          exact
          path='/statistika-agorwn/manufacturer-type'
          element={<StatistikaAgorwnManufacturerType />}
        />
        <Route exact path='/apografi/category' element={<ComingSoon />} />
        <Route exact path='/apografi/manufacturer' element={<ComingSoon />} />
        <Route exact path='/apografi/manufacturer-type' element={<ComingSoon />} />
        <Route exact path='/change-password' element={<ChangePassword />} />
        <Route path='*' element={<NotFound type='internal' />} />
      </Route>
    </Routes>
  </Suspense>
);

export default function StatusEndUser() {
  return (
    <Layout>
      <SuspenseLayout />
    </Layout>
  );
}
