import React, { useContext, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
// import Lottie from "lottie-react";
// import error from '../../../assets/Error404.json'
// import PrimaryBtn from '../../../components/PrimaryBtn';

import { AuthContext } from '../../../contexts/AuthContext';

export default function NotFound({ type = 'external' }) {
  const navigate = useNavigate();

  const { auth } = useContext(AuthContext);

  useEffect(() => {
    if (!auth) {
      return navigate('/');
    }
  }, [auth]);

  return (
    <motion.div
      style={{
        height: 'calc(100vh - 253px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      initial={{ opacity: 0, translateY: 0 }}
      animate={{ opacity: 1, translateY: 0 }}
      exit={{ opacity: 0, translateY: 0 }}
      transition={{ duration: 0.25, delay: 0 }}>
      <div>
        {type === 'external' ? (
          <>
            <h4>Η σελίδα δεν βρέθηκε</h4>
          </>
        ) : null}
      </div>
    </motion.div>
  );
}
