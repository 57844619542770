import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../../layouts/Layout/Layout';
import ScreenLoader from '../../components/ScreenLoader';
import NotFound from '../../routes/Public/NotFound';

const Dashboard = lazy(() => import('../../routes/Helpdesk/Dashboard/Dashboard'));
const UsersList = lazy(() => import('../../routes/Helpdesk/Users/UsersList/UsersList'));
const AddUser = lazy(() => import('../../routes/Helpdesk/Users/AddUser/AddUser'));
const DatabasesList = lazy(() =>
  import('../../routes/Helpdesk/Databases/DatabasesList/DatabasesList')
);
const AddDatabase = lazy(() => import('../../routes/Helpdesk/Databases/AddDatabase/AddDatabase'));
const ChangePassword = lazy(() => import('../../routes/Helpdesk/ChangePassword/ChangePassword'));
const Exceptions = lazy(() => import('../../routes/Helpdesk/Logs/Exceptions/Exceptions'));
const WorkersLogs = lazy(() => import('../../routes/Helpdesk/Logs/WorkersLogs/WorkersLogs'));

const SuspenseLayout = () => (
  <Suspense fallback={<ScreenLoader size='medium' />}>
    <Routes>
      <Route index path='/' element={<Dashboard />} />
      <Route exact path='/users' element={<UsersList />} />
      <Route exact path='/add-user' element={<AddUser />} />
      <Route exact path='/change-password' element={<ChangePassword />} />
      <Route exact path='/databases' element={<DatabasesList />} />
      <Route exact path='/add-database' element={<AddDatabase />} />
      <Route exact path='/exceptions' element={<Exceptions />} />
      <Route exact path='/workers-logs' element={<WorkersLogs />} />
      <Route path='*' element={<NotFound type='internal' />} />
    </Routes>
  </Suspense>
);

export default function Helpdesk() {
  return (
    <Layout>
      <SuspenseLayout />
    </Layout>
  );
}
