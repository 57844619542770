import React from 'react';
import styles from './layout.module.scss';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import CustomMediaQueries from '../../utils/customMediaQueries';

function Layout({ children }) {
  return (
    <>
      <div className={styles.AppContainer}>
        <CustomMediaQueries>
          {({ isTabletDown }) => (
            <main
              className={
                isTabletDown
                  ? [styles.PageMain, styles.IsMobile].join(' ')
                  : [styles.PageMain, styles.IsDesktop].join(' ')
              }>
              <Sidebar />
              <div className={styles.PageContent}>
                <Header />
                <div className={styles.PageContentInner}>{children}</div>
              </div>
            </main>
          )}
        </CustomMediaQueries>
      </div>
    </>
  );
}

export default Layout;
