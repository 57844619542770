import { memo, useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './navitemdropdown.module.scss';
import { ThemeContext } from '../../contexts/ThemeContext';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

function NavItemDropdown({ menu }) {
  const { isSidebarToggled, isSidebarOpened, setIsSidebarOpened } = useContext(ThemeContext);

  const [activeClass, setActiveClass] = useState(false);

  // Initialize the active parent index from local storage or null
  const initialActiveParentIndex = parseInt(localStorage.getItem('activeParentIndex'));
  const [activeParentIndex, setActiveParentIndex] = useState(
    initialActiveParentIndex !== null ? initialActiveParentIndex : 0
  );

  useEffect(() => {
    if (localStorage.getItem('activeParentIndex') === null) {
      setActiveParentIndex(0);
      localStorage.setItem('activeParentIndex', 0);
    }
  }, []);

  const [openMenuIndexes, setOpenMenuIndexes] = useState(() => {
    // Initialize all menu item indices as open (true)
    const initialOpenMenuIndexes = {};
    menu.forEach((item, i) => {
      initialOpenMenuIndexes[i] = i === activeParentIndex;
    });
    return initialOpenMenuIndexes;
  });

  // Toggle the submenu of a menu item
  const toggleSubMenu = (index) => {
    setOpenMenuIndexes((prevOpenMenuIndexes) => ({
      ...prevOpenMenuIndexes,
      [index]: !prevOpenMenuIndexes[index],
    }));
  };

  // Update the active parent index when a child is active
  const handleChildActivation = (parentIndex) => {
    setActiveParentIndex(parentIndex);
    localStorage.setItem('activeParentIndex', parentIndex);
  };

  const localStorageIndex = localStorage.getItem('activeParentIndex');

  const isLocalStorageNull = localStorageIndex === null;

  const shouldSetActive = (submenuItem) => {
    return isLocalStorageNull && submenuItem.index === 0 ? true : false;
  };

  const unsetActiveClass = () => {
    setActiveClass(false);
  };

  return (
    <>
      {menu
        ? menu.map((item, i) => (
            <div className={styles.NavGroup} key={i}>
              {item.active ? (
                <li
                  className={[
                    styles.NavItemLabel,
                    styles._isClickable,
                    isSidebarToggled ? styles._isToggled : null,
                  ].join(' ')}
                  key={i}>
                  {item.url === '' ? (
                    item.iconLabel ? (
                      <div
                        className={styles.NavItemTitle}
                        onClick={() => {
                          toggleSubMenu(i);
                          handleChildActivation(i);
                          unsetActiveClass();
                        }}>
                        <span className={styles.NavItemTitleIcon}>{item.iconLabel}</span>
                        <h5 className={styles.NavItemTitleText}>{item.title}</h5>
                        <span className={styles.NavItemTitleArrow}>
                          {openMenuIndexes[i] ? <FiChevronUp /> : <FiChevronDown />}
                        </span>
                      </div>
                    ) : (
                      <h5>{item.title}</h5>
                    )
                  ) : item.singleItem ? (
                    <NavLink
                      to={item.url}
                      className={[styles.NavItemTitle, activeClass ? styles.Active : null].join(
                        ' '
                      )}
                      onClick={() => setActiveClass(true)}>
                      <span className={styles.NavItemTitleIcon}>{item.iconLabel}</span>
                      <h5 className={styles.NavItemTitleText}>{item.title}</h5>
                    </NavLink>
                  ) : (
                    <NavLink to={item.url}>{item.title}</NavLink>
                  )}
                </li>
              ) : null}
              {/* Render the submenu if the menu item is open */}
              {openMenuIndexes[i] && item.submenu.length
                ? item.submenu.map((submenuItem, idx) => (
                    <li
                      className={[styles.NavItem, isSidebarToggled ? styles._isToggled : null].join(
                        ' '
                      )}
                      key={idx}>
                      <NavLink
                        to={submenuItem.url}
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.NavItemLinkActive} ${styles.NavItemLink}`
                            : styles.NavItemLink
                        }
                        onClick={() => {
                          unsetActiveClass();
                          setIsSidebarOpened(!isSidebarOpened);
                        }}>
                        <span className={styles.NavItemIcon}>{submenuItem.icon}</span>
                        <span className={styles.NavItemTitle}>{submenuItem.name}</span>
                      </NavLink>
                    </li>
                  ))
                : null}
            </div>
          ))
        : null}
    </>
  );
}

export default memo(NavItemDropdown);
