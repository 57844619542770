import React, { useState, useEffect, createContext } from 'react';
import { useCookies } from 'react-cookie';
import privateAxios from '../network/private-axios';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import BASEURL from '../utils/BASEURL';

export const AuthContext = createContext({});

export const AuthContextProvider = (props) => {
  const { children } = props;

  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState(null);
  const [forbidden, setForbidden] = useState(null);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [errorCallMsg, setErrorCallMsg] = useState(null);

  useEffect(() => {
    if (cookies.auth) {
      const expirationDate = new Date(cookies.auth.expires);
      const currentDate = new Date();

      if (expirationDate > currentDate) {
        getUser(cookies.auth.u, cookies.auth.token);
      } else {
        removeCookie('auth', { path: '/', expires: new Date(cookies.auth.expires) });
        setAuth(false);
        setUser(null);
      }
    }
  }, [auth]);

  //   GET USER
  async function getUser(
    user = cookies.auth.u,
    token = cookies.auth.token,
    guid = cookies.auth.guid
  ) {
    const { data, error } = await privateAxios.get(
      `${BASEURL}/api/user-manager/user?identityID=${guid}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (
      (data.result.isActive && !data.result.isLocked) ||
      data.result.role === 'Helpdesk' ||
      data.result.role === 'StatusEndUser'
    ) {
      setUser(data.result);
      setAuth(true);
      setLoaderVisible(false);
      setErrorCallMsg(null);
    } else {
      removeCookie('auth', { path: '/', expires: new Date(cookies.auth.expires) });
      setUser(null);
      setAuth(false);
      setLoaderVisible(false);
      setErrorCallMsg(null);
    }
    if (error) {
      setLoaderVisible(false);
    }

    return data.result;
  }

  //   GET USER
  const GetUser = (user = cookies.auth.u, token = cookies.auth.token) => {
    privateAxios
      .get(`${BASEURL}/api/user-manager/user?email=${user}`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        if (response.data.result.isActive && !response.data.result.isLocked) {
          setUser(response.data.result);
          setAuth(true);
          setLoaderVisible(false);
          setErrorCallMsg(null);
        } else {
          removeCookie('auth', { path: '/', expires: new Date(cookies.auth.expires) });
          setUser(null);
          setAuth(false);
          setLoaderVisible(false);
          setErrorCallMsg(null);
        }
      })
      .catch(function (error) {
        setLoaderVisible(false);
      });
  };

  //   LOGIN USER
  const LoginUser = (username, password) => {
    setErrorCallMsg(null);
    setLoaderVisible(true);

    axios
      .post(`${BASEURL}/api/auth/login`, {
        email: username,
        password: password,
      })
      .then(function (response) {
        if (!response.data.result.token) {
          setLoaderVisible(false);
          setErrorCallMsg(response.data.result.message);
        } else {
          setCookie(
            'auth',
            {
              token: response.data.result.token,
              expires: response.data.result.expireAt,
              guid: response.data.result.userIdentityID,
              u: username,
            },
            {
              expires: new Date(response.data.result.expireAt),
              path: '/',
            }
          );

          getUser(username, response.data.result.token, response.data.result.userIdentityID);
        }
      })
      .catch(function (error) {
        // console.log(error);
        setLoaderVisible(false);
        setErrorCallMsg('Το username ή/και ο κωδικός δεν είναι σωστά');
      });
  };

  //   LOGOUT USER
  const LogoutUser = () => {
    removeCookie('auth', { path: '/', expires: new Date(cookies.auth.expires) });
    setUser(null);
    setAuth(null);
  };

  const authContext = {
    auth,
    setAuth,
    user,
    setUser,
    getUser,
    GetUser,
    LoginUser,
    LogoutUser,
    loaderVisible,
    setLoaderVisible,
    errorCallMsg,
    forbidden,
    setForbidden,
  };

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};
