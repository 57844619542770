import React, { useContext, useRef } from 'react';
import { SideNav } from '../SideNav/SideNav';
import styles from './sidebar.module.scss';
import { AnimatePresence, motion } from 'framer-motion';
import SidebarBottom from './SidebarBottom/SidebarBottom';
import { ThemeContext } from '../../contexts/ThemeContext';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GrClose } from 'react-icons/gr';
import { AuthContext } from '../../contexts/AuthContext';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import CustomMediaQueries from '../../utils/customMediaQueries';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/status-reports-logo-light.png';
import useClickOutside from '../../utils/useClickOutside';

function Sidebar() {
  const { user } = useContext(AuthContext);
  const { isSidebarToggled, setIsSidebarToggled } = useContext(ThemeContext);
  const { isSidebarOpened, setIsSidebarOpened } = useContext(ThemeContext);

  const openedSidebar = () => {
    setIsSidebarOpened(false);
  };

  const toggleSidebar = () => {
    user.role !== 'StatusEndUser' && setIsSidebarToggled(!isSidebarToggled);
  };

  const sidebarRef = useRef();
  // useClickOutside(sidebarRef, openedSidebar);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0,
      },
    },
    show: {
      opacity: 1,
      width: 'auto',
      transition: {
        duration: 0,
      },
    },
  };

  return (
    <>
      <CustomMediaQueries>
        {({ isDesktopSmallDown, isTabletDown }) => (
          <motion.div
            animate={{
              flex: isSidebarToggled ? '0 0 80px' : '0 0 300px',
              width: isSidebarToggled ? '80px' : '300px',
              padding: isSidebarToggled ? '1.5rem' : '2.5rem',
              transition: {
                duration: 0.65,
                type: 'spring',
                damping: 15,
              },
            }}
            id={styles.PageSidebar}
            ref={sidebarRef}
            className={[
              !isSidebarOpened
                ? [styles.PageSidebar, styles.ToggleMobile].join(' ')
                : styles.PageSidebar,
              isDesktopSmallDown ? styles.IsMobile : null,
            ].join(' ')}>
            <div className={styles.SidebarContent}>
              <motion.div
                className={[styles.SidebarTop, isSidebarToggled ? styles._isToggled : null].join(
                  ' '
                )}>
                <div className={styles.SidebarLogo}>
                  <AnimatePresence>
                    {!isSidebarToggled && (
                      <NavLink to='/'>
                        <motion.img
                          variants={showAnimation}
                          initial='hidden'
                          animate='show'
                          exit='hidden'
                          src={Logo}
                          style={{
                            maxWidth: 150,
                            width: '100%',
                            display: 'block',
                            marginBottom: 2,
                          }}
                          alt='Status Reports by MainSys'
                        />
                      </NavLink>
                    )}
                  </AnimatePresence>
                </div>
                <MediaQuery maxWidth={991}>
                  <div
                    className={styles.SidebarClose}
                    onClick={() => setIsSidebarOpened(!isSidebarOpened)}>
                    <GrClose className={styles.Icon} />
                  </div>
                </MediaQuery>
                {!user.role === 'StatusUserEnd' ? (
                  <div className={styles.SidebarToggle}>
                    <GiHamburgerMenu onClick={toggleSidebar} />
                  </div>
                ) : null}
              </motion.div>
              <div className={styles.SidebarMain}>
                <SideNav />
              </div>
              <motion.div className={styles.SidebarBottom}>
                <SidebarBottom />
              </motion.div>
            </div>
          </motion.div>
        )}
      </CustomMediaQueries>
    </>
  );
}

export default Sidebar;
