import React, { useContext, useEffect, useState } from 'react';
import styles from './header.module.scss';
import { AuthContext } from '../../contexts/AuthContext';
import HeaderRight from './HeaderRight/HeaderRight';
import MediaQuery from 'react-responsive';
import Logo from '../../assets/status-reports-logo-dark.png';
import { NavLink } from 'react-router-dom';

function Header() {
  const [currentDate, setCurrentDate] = useState('');
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const getCurrentDate = () => {
      const options = {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };
      const today = new Date();

      return today.toLocaleDateString('el-GR', options);
    };

    setCurrentDate(getCurrentDate());
  }, []);

  return (
    <header id={styles.PageHeader}>
      <div className={styles.HeaderContainer}>
        <MediaQuery maxWidth={991}>
          <NavLink to='/'>
            <img
              src={Logo}
              style={{ maxWidth: 150, width: '100%', display: 'block', marginBottom: 2 }}
              alt='Status Reports by MainSys'
            />
          </NavLink>
        </MediaQuery>
        <MediaQuery minWidth={991}>
          <div className={styles.HeaderLeft}>
            <h5 className={styles.WelcomeUser}>Καλώς ήρθες, {user.firstName}</h5>
            <h6 className={styles.WelcomeDate}>{currentDate}</h6>
          </div>
        </MediaQuery>
        <div className={styles.HeaderRight}>
          <HeaderRight />
        </div>
      </div>
    </header>
  );
}

export default Header;
