import React from 'react';
import styles from './index.module.css';
import { IoAlertCircleSharp } from 'react-icons/io5';

export default function InputError({ error }) {
  return (
    <div className={styles.Error}>
      <IoAlertCircleSharp className={styles.ErrorIcon} />
      <span className={styles.ErrorText}>{error}</span>
    </div>
  );
}
