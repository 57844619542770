import { useContext, memo } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { AppMenuLinks } from '../../data/sidebarLinks';
import NavItemDropdown from '../../components/NavItemDropdown/NavItemDropdown';

export const SideNav = () => {
  const { user } = useContext(AuthContext);
  const role = user.role;

  return (
    <nav className='SidebarNav'>
      <ul className='SideBarMenu'>
        {AppMenuLinks.map((menuItem, i) => {
          if (role) {
            return <NavItemDropdown menu={menuItem[role]} key={i} />;
          }
        })}
      </ul>
    </nav>
  );
};

// export default Sidenav;
